html,
body {
  word-break: break-word;
  font-family: "Poppins";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

body .modal-backdrop {
  background-color: #3b3b3b;
  display: none !important;
}

body .modal-backdrop.show {
  opacity: 0.08;
  display: block !important;
}

body .modal-dialog-centered {
  min-height: 100%;
}

body.modal-open {
  padding-right: 0 !important;
  max-height: 100vh;
  overflow-y: auto !important;
}

body .modal {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body .fade.modal {
  display: none !important;
}

body .fade.modal.show {
  display: block !important;
}

body .modal::-webkit-scrollbar {
  display: none;
}

body ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

body .Toastify,
body .Toastify__toast {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #3b3b3b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body .pac-logo:after {
  display: none;
}

body .pac-container {
  font-family: "Poppins";
}

body .pac-container .pac-item {
  font-family: "Poppins";
  padding: 4px 10px;
  font-size: 12px;
  color: #8d8d8d;
  cursor: pointer;
  transition: all 0.2s ease 0s;
}

body .pac-container .pac-item:hover {
  background: #f3f3f3;
}

body .pac-container .pac-item-query {
  font-family: "Poppins";
  color: #3b3b3b;
  font-weight: 500;
}

body .pac-container .pac-item-query .pac-matched {
  color: #151515;
}
.modal-50w {
  max-width: 370px !important;
}
@media (max-width: 1199px) {
  body.right-panel-open {
    position: fixed;
    overflow: hidden;
    left: 0;
    right: 0;
  }
  body.right-panel-open .userHeader {
    z-index: 11;
  }
  body.left-panel-open {
    position: fixed;
    overflow: hidden;
    left: 0;
    right: 0;
  }
  body.left-panel-open .userHeader {
    z-index: 11;
  }
  body.right-panel-open::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    top: 99px;
    background-color: rgba(59, 59, 59, 0.08);
    z-index: 10;
  }
  body.left-panel-open::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    top: 99px;
    background-color: rgba(59, 59, 59, 0.08);
    z-index: 10;
  }
}

/*time css*/

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  box-sizing: content-box;
  overflow-x: hidden;
  display: block;
  position: relative;
}

.react-datepicker__time-container {
  width: 100%;
}
.show-more-less-clickable {
  text-decoration: none !important;
  color: #0d6efd;
}

/* slider css*/
.ant-slider-handle,
.ant-slider-track {
  background-color: #1625e3 !important;
}

.ant-slider-handle {
  width: 20px !important;
  height: 20px !important;
  border: none !important;
}

.ant-slider-track,
.ant-slider-rail {
  top: 7px !important;
}

.ql-size-small {
  font-size: 0.75em;
}

.ql-size-large {
  font-size: 1.5em;
}
.ql-size-huge {
  font-size: 2.5em;
}
